import React from "react";
import { Helmet } from "react-helmet";
import { Hero, Slider } from "../components";
import "./../styles/index.scss";
import Mail from "./../images/mail.svg";
import Linkedin from "./../images/linkedin.svg";
import Github from "./../images/github.svg";
import Twitter from "./../images/twitter.svg";

export default ({ data }) => (
  <>
    <Helmet>
      <title>Mert Eroğlu - Developer</title>
      <meta
        name="description"
        content=""
      />
      <meta
        name="keywords"
        content="developer, software engineer, programmer, backend developer, mobile, java, javascript, react-native, react, ios, android, docker, k8s, kubernetes"
      />
    </Helmet>
    <Hero />
    <section className="section" id="about-me">
      <div className="container">
        <div className="columns is-vcentered is-centered">
          <div className="column is-6 has-text-right">
            <h3 className="title is-1 has-text-primary">About Me</h3>
            <h5 className="subtitle">What I Know</h5>
            <div className="content">
              <p>
                I've started to get an interest in programming when I was 10. I was writing plugins and themes for
                WordPress when 12.
                After that, I met VB6 and write windows applications. I worked in a team which developed bots for MMORPG
                during my high school life.
                When I started college, I developed tens of applications with my friends. For several years I focused on
                backend development. Programming is still my favorite hobby.
              </p>
            </div>
          </div>
          <div className="column content">
            <ul>
              <li>Java</li>
              <li>SQL</li>
              <li>Javascript</li>
              <li>Kafka</li>
              <li>Redis</li>
              <li>Microservices</li>
              <li>Docker</li>
              <li>K8s</li>
              <li>ELK</li>
              <li>Mobile Development</li>
              <li>React</li>
              <li>React-Native</li>
              <li>CI/CD</li>

            </ul>
            <p className="is-rainbow-text">and more</p>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-primary-100" id="about-me">
      <div className="container">
        <div className="columns is-vcentered is-centered">
            <div className="content">
              <h4 className="title is-2 text-align-center">Contact Me</h4>
              <ul className="mini-links">
                <li>
                  <a href="https://www.linkedin.com/in/mert-eroglu/">
                    <Linkedin className="contact-icons"/>
                  </a>
                </li>
                <li>
                  <a href="https://github.com/merteroglu">
                    <Github className="contact-icons"/>
                  </a>
                </li>

                <li>
                  <a href="https://twitter.com/16paxwel">
                    <Twitter className="contact-icons" />
                  </a>
                </li>

                <li>
                  <a href="mailto:merteroglu16@gmail.com">
                    <Mail className="contact-icons" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </section>
    <Slider />
  </>
);
