import React from "react";
import IronMan from "../images/ironman.svg";
import "./phone.scss";

export const Phone = () => (
  <div id="phone">
    <div className="inner-skin">
      <div className="phone-content">
        <h1 className="title">Hello</h1>
        <IronMan className="bounce" />
        <h2 className="subtitle">Let's build together...</h2>
        <button
          className="button has-rainbow-background has-text-white is-rounded"
        >
          <a href="#contact-me">Contact Me</a>
        </button>
      </div>
    </div>
  </div>
);
