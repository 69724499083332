export enum ProjectStatus {
  ACTIVE = "active",
  CEASED = "ceased",
  ONHOLD = "on hold",
  PLANNING = "planning",
  COMPLETE = "complete",
  DEVELOPMENT = "development",
}

export type Slide = {
  status: ProjectStatus;
  name: string;
  description: string;
  link?: string;
  android?: string;
  ios?: string;
  technologies?: string[];
  image?: string;
};

export const slides: Slide[] = [
  {
    status: ProjectStatus.ACTIVE,
    name: "KOBS",
    description:
      "A mobile app for Kocaeli University",
    technologies: [
      "javascript",
      "java",
      "spring",
      "react native",
      "redis",
    ],
    image: "kobs",
    android: "https://play.google.com/store/apps/details?id=com.kobs",
    ios: "https://apps.apple.com/us/app/kobs-kou-bilgi-sistemi/id1437143716",
  },
  {
    status: ProjectStatus.ACTIVE,
    name: "What's the name of the song",
    description:
      "A mobile riddle game, listen songs' short sections guess song's name ",
    technologies: [
      "java",
      "android native",
      "spotify api integration",
      "firebase",
    ],
    image: "song",
    android: "https://play.google.com/store/apps/details?id=com.merteroglu.buhangisarki",
  },
];
